* {
    box-sizing: border-box;
    text-align: center;
}

.treeEditorContainer {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}

.sidebar {

    width: 30%;

    padding: 1%;
    
}

.ruleLayout {

    width: 70%;
    
}

.sidebar h2 {
    
}


.formFields, .formRecipients, .formRules {
    
}

.formOptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.formField, .formRule, .formValue, .formOperator, .formRecipient {
    color: white;
    background-color: #666666;
    border: 2px dashed #999999;
    border-radius: 2px;
    
    line-height: 2rem;
    padding: 0 0.5rem;

    margin: 0.5rem;
    
    min-width: 25%;
}

.formField:hover, .formRule:hover, .formValue:hover, .formOperator:hover, .formRecipient:hover {

    color: #333333;
    background-color: #CCCCCC;
    /* cursor: move; */
    cursor: pointer;
}

.formOperator {
    background-color: #999999;
}

.formValue {
    color: black;
    background-color: #EEEEEE;
}

.formRecipient {
    background-color: #333333;
}

.optionSelectedLight {

    margin: 0;
    border: 0.25rem solid #CCCCCC;
}

.optionSelectedDark {

    margin: 0;
    border: 0.25rem solid #333333;
}


.formRules h3 {

    margin: 0.5rem;
    text-align: left;
}

.ruleBox, .dropArea {

    border: 2px solid #CCCCCC;

    min-height: 3rem;
    padding: 0.5rem;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;

    /* padding: 0.5rem 0; */
    /* margin: 0.25rem 0; */
    margin-top: 0.5rem;

    cursor: pointer;
}

.ruleBox:hover, .dropArea:hover {
    background-color: #EEEEEE;
}

.dropArea {
    border: 2px dashed #666666;
    color: #666666;

    max-width: 300px;
}

.choiceYes, .choiceNo {
    display: flex;
    /* flex-direction: column; */

}

.yesNoText {
    font-size: 1.5rem;
    font-weight: bold;

    /* margin: 1rem 0;  */
    display: inline-block;
    min-width: 4rem;

    padding-right: 0;

    height: 4rem;
}

.verticalLine, .horizontalLine {
    width: 1px;
    border: 2px solid #999999;
    margin: 0 auto;
}

.rightArrowContainer {
    display: flex;
}

.horizontalLine {
    margin-top: 2.5rem;
    width: 1rem;
    height: 1px;
}

.rightArrow {
    width: 0; 
    height: 0;
    
    margin-top: 2.25rem;
    
    border: 6px solid transparent;
    border-left: 10px solid #999999;
  }

.lineAboveYes, .lineAboveNo {
    height: 1.5rem;
}

.yesTextParent{
    display: flex;
    flex-direction: column;
    /* align-items: stretch; */
}

.lineBelowYesContainer {
    height: 100%;
}

.lineBelowYes {
    
    height: 100%;
}

.deleteButtons {
    
    /* put in top right corner of parent container */
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
    margin-right: -0.25rem;

    height: 3.5rem;
    
    margin-left: auto;
    align-self: flex-start;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

.clearContentsButton {
    margin-top: -0.5rem;
}

.deleteNodeButton {
    margin-bottom: -1rem;
}


.clearContentsButton, .deleteNodeButton {
    color: #CCCCCC;

    cursor: pointer;
    
    font-weight: bold;
}

.deleteNodeButton {
    font-size: 1.5rem;
}

.clearContentsButton:hover {
    color: #993333;
}

.deleteNodeButton:hover {
    color: #993333;
}